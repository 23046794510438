import { Injectable, OnDestroy } from '@angular/core';
import {
  DocumentReference,
  Firestore,
  collection,
  doc,
  docData,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription, of, switchMap } from 'rxjs';
import { MetaService } from './meta.service';

const COLLECTION_NAME = 'org';

@Injectable({
  providedIn: 'root',
})
export class OrgService implements OnDestroy {
  activeOrgId$ = new BehaviorSubject<string | null>(
    localStorage.getItem('activeOrgId') ?? null
  );

  activeOrgSub: Subscription;

  constructor(private metaService: MetaService, private firestore: Firestore) {
    this.activeOrgSub = this.activeOrgId$.subscribe((id) => {
      localStorage.setItem('activeOrgId', id ?? '');
    });
  }

  ngOnDestroy(): void {
    this.activeOrgSub.unsubscribe();
  }

  collectionRef() {
    return collection(this.firestore, COLLECTION_NAME);
  }

  orgRefForId(id: string | null): DocumentReference<ArchOrg> {
    const ref = this.collectionRef();
    return (id ? doc(ref, id) : doc(ref)) as DocumentReference<ArchOrg>;
  }

  org$(id: string) {
    return docData(this.orgRefForId(id), {
      idField: 'id',
    }) as Observable<ArchOrg>;
  }

  activatedOrg$() {
    return this.activeOrgId$.pipe(
      switchMap((id) => {
        return id ? this.org$(id) : of(null);
      })
    );
  }

  async saveOrg(model: any) {
    return model.id ? this.updateOrg(model) : this.addOrg(model);
  }

  async addOrg(model: any) {
    const meta = await this.metaService.metaize(model);
    const ref = this.orgRefForId(null);
    await setDoc(ref, {
      ...model,
      id: ref.id,
      owner: {
        uid: meta.createdUid,
        email: meta.createdBy,
      },
      meta,
    });
  }

  async updateOrg(model: any) {
    const meta = await this.metaService.metaize(model);
    return updateDoc(this.orgRefForId(model.id), {
      ...model,
      meta: await this.metaService.metaize(model),
    });
  }
}

export interface ArchOrg {
  id: string;
  name: string;
  meta: {
    created: Date;
    createdUid: string;
    createdBy: string;
    updated: Date;
    updatedUid: string;
    updatedBy: string;
  };
  coachName?: string;
  coacheEmail?: string;
  billing?: {
    email?: string;
    phone?: string;
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
}

export interface ArchOrgRef {
  id: string;
  name: string;
  coachName?: string;
  coachEmail?: string;
}
