import { Pipe, PipeTransform } from '@angular/core';
import { ArchBowlEntry } from '../@services/competition.service';

/**
 * Calculates the total score for specified questions in an ArchBowl competition entry.
 *
 * @example
 * // In template:
 * {{ entry | testScore:['question1', 'question2'] }}
 *
 * // Returns the sum of scores for the specified question IDs
 */
@Pipe({
  name: 'testScore',
  standalone: true,
})
export class TestScorePipe implements PipeTransform {
  /**
   * Transforms an ArchBowl entry by summing the scores of specified questions.
   *
   * @param entry - The competition entry containing participant's scores
   * @param questionIds - Array of question IDs to include in the total
   * @returns The sum of scores for the specified questions, or null if entry/scores are invalid
   */
  transform(entry: ArchBowlEntry, questionIds: string[]) {
    if (!entry || !entry.state || !entry.state.scores) return null;
    const scores = entry.state.scores ?? {};
    return Object.keys(scores)
      .filter((key) => questionIds.includes(key))
      .map((key) => scores[key])
      .reduce((acc, score) => acc + score, 0);
  }
}
